<template>
  <div class="home">
    <!-- 中间页 -->
    <div id="content">
      <div
        class="beijingtupian"
        :style="{ background: `url(${imageList})`}"
      >
      </div>
      <div class="waitao">
          <img
            src="../assets/wenzi1.svg"
            style="
              width: 593px;
              height: 56px;
              top: 183px;
              position: absolute;
            "
          /><img
            src="../assets/wenzi2.svg"
            style="
              width: 593px;
              height: 17px;
              top: 278px;
              position: absolute;
            "
          />
        </div>
      <div class="middle">
        <h6 class="title1">COMPANY PROFILE</h6>
        <h6 class="title2">公司介绍</h6>
        <p class="intro1">
          北京天卓科技有限公司成立于2015年，注册资金1000万元，公司精耕互联网广告行业，运用人工智能和大数据等技术，为广告主、代理商和媒体主提供智能广告投放及流量聚合的一站式解决方案。经过连续几年的高速发展，公司与不同类型的优质媒体达成战略合作，并成为广告核心代理商。通过强大的资源整合能力、完善的客户体系、优质头部媒体流量和专业的技术积累，通过打造自有移动互联网智能运营平台，引领行业发展，为广告主与媒体创造最大价值。
        </p>
      </div>
      <div style="width: 100%; background: #f7faff">
        <div class="middle1">
          <h6 class="title3">MAIN BUSINESS</h6>
          <h6 class="title2">主营业务</h6>
          <div class="sp">
            <div class="sp1" v-for="(item, index) in list" :key="index">
              <img
                :src="item.img"
                style="padding: 0 48px 0 48px; height: 140px; width: 140px"
              />
              <h6 class="title4">{{ item.title }}</h6>
              <p class="intro2">{{ item.descrite }}</p>
              <router-link :to="item.link" class="jiantou"></router-link>
            </div>
            <!--<div class="sp1">
            <img src ="../assets/shjf.svg" style="padding: 0 48px 0 48px;">
            <h6 class="title4">珊瑚积分平台</h6>
            <p class="intro2">积分媒介，用户运营玩法带来自增长，利用社交流量池、新兴流量池，实现用户的全新增长方式</p>
            <div class="jiantou"></div>
          </div>
          <div class="sp1">
            <img src ="../assets/rhdsp.svg" style="padding: 0 48px 0 48px;">
            <h6 class="title4">TIANZ融合DSP平台</h6>
            <p class="intro2">TIANZ DSP平台依托核心智能推荐技术，与国内大量中小媒体达成战略合作，整合安卓端和IOS端优质流量资源</p>
            <div class="jiantou1"></div>
          </div>
          <div class="sp1">
            <img src ="../assets/hxmt.svg" style="padding: 0 48px 0 48px;">
            <h6 class="title4">核心媒体资源</h6>
            <p class="intro2">1.腾讯安全工具APP<br>
              2.中国移动咪咕广告平台<br>
              3.掌上高铁<br>
              4.今日头条广告平台</p>
            <div class="jiantou2"></div>  
          </div>
          <div class="sp1">
            <img src ="../assets/kjds.svg" style="padding: 0 48px 0 48px;">
            <h6 class="title4">跨境电商</h6>
            <p class="intro2">我们是阿里SKA客户，营销资源充足，经营范围广，业务辐射整个欧美地区，并且正在向其他地区持续扩展</p>
            <div class="jiantou3"></div>
          </div>-->
          </div>
          <router-link to="/About" class="title5"
            >更多业务产品<img
              src="../assets/gdcp.svg"
              style="top: 3px; position: relative"
          /></router-link>
        </div>
      </div>
      <div class="middle2">
        <h6 class="title1">OUR ADVANTAGE</h6>
        <h6 class="title2">我们的优势</h6>
        <img src="../assets/wmdys.svg" style="margin: 40px 80px 0 0" />
        <div style="height: 0">
          <h6 class="title6">
            精耕移动互联网流量生态，致力于成为一流的新媒体全域营销运营商
          </h6>
          <div class="dian1"><div class="dian"></div></div>
          <h6 class="title7">主营平台</h6>
          <p class="intro3">
            公司主营的珊瑚积分平台，是以<span class="b1"
              >数据、资源、服务、技术</span
            >为核心要素，利用社交流量池+新兴流量池，为众多APP实现用户运营成长和流量变现的创新型智能运营平台。
          </p>
          <div class="dian2"><div class="dian"></div></div>
          <h6 class="title8">自有平台</h6>
          <p class="intro4">
            公司自有DSP平台，融合<span class="b1">5000+</span
            >中小媒体资源，为流量生态的更全面覆盖助力。
          </p>
          <div class="dian3"><div class="dian"></div></div>
          <h6 class="title9">合作平台</h6>
          <p class="intro5">
            公司已与<span class="b1">腾讯、中国移动咪咕、掌上高铁</span
            >等行业优质媒体达成战略合作，作为今日头条KA核心代理，公司拥有强大的优化运营团队。
          </p>
        </div>
      </div>
      <div style="width: 100%; background: #f7faff">
        <div class="middle3">
          <h6 class="title3">DEVELOPMENT COURSE</h6>
          <h6 class="title2">发展历程</h6>
          <div class="line">
            <img
              src="../assets/yuanquan.svg"
              style="position: absolute; top: 165px; z-index: 3"
            />
            <img
              src="../assets/xianduan.svg"
              style="position: absolute; top: 175px; left: 10px; z-index: 2"
            />
            <h6 class="title10">2021</h6>
            <p class="intro6">
              公司成立跨境电商事业部，运营五家金品诚企店铺，成为<span class="b1"
                >阿里国际站SKA商家</span
              >，布局海外市场
            </p>
          </div>
          <div class="line">
            <img
              src="../assets/yuanquan.svg"
              style="position: absolute; top: 283px; z-index: 3"
            />
            <h6 class="title11">2020</h6>
            <p class="intro7">
              公司成立技术部和运营部；搭建<span class="b1">智慧投</span
              >-投放平台/搭建<span class="b1">聚优量</span>-流量平台/搭建<span
                class="b1"
                >嗨创意</span
              >-创意营销平台
            </p>
          </div>
          <div class="line">
            <img
              src="../assets/yuanquan.svg"
              style="position: absolute; top: 401px; z-index: 3"
            />
            <h6 class="title12">2019</h6>
            <p class="intro8">
              公司成立信息流事业部与新媒体事业部；成为<span class="b1"
                >掌上高铁</span
              >3C/化妆品/互金行业独家代理；搭建智量汇广告内容聚合平台，成为<span
                class="b1"
                >腾讯珊瑚积分运营平台</span
              >核心服务商
            </p>
          </div>
          <div class="line">
            <img
              src="../assets/yuanquan.svg"
              style="position: absolute; top: 543px; z-index: 3"
            />
            <h6 class="title13">2018</h6>
            <p class="intro9">
              成为腾讯手机管家、腾讯WiFi管家、QQ同步助手TOP1代理商，与<span
                class="b1"
                >腾讯</span
              >达成战略合作；成为<span class="b1">今日头条</span
              >广告平台核心代理；成为<span class="b1">百度小游戏</span
              >首批代理商；成为AliOS基于IoT技术的<span class="b1"
                >场景化营销广告</span
              >首批测试合作伙伴
            </p>
          </div>
          <div class="line">
            <img
              src="../assets/yuanquan.svg"
              style="position: absolute; top: 688px; z-index: 3"
            />
            <h6 class="title14">2017</h6>
            <p class="intro10">
              成为中国移动旗下咪咕广告平台核心代理，并与行业知名海外流量平台-<span
                class="b1"
                >推啊平台</span
              >达成战略合作；成为<span class="b1">今日头条</span
              >TOP10渠道商，芒果TV TOP1渠道商，<span class="b1">咪咕视讯</span
              >TOP1渠道商
            </p>
          </div>
          <div class="line">
            <img
              src="../assets/yuanquan.svg"
              style="position: absolute; top: 833px; z-index: 3"
            />
            <h6 class="title15">2016</h6>
            <p class="intro11">
              成为<span class="b1">腾讯手机管家</span>、<span class="b1"
                >腾讯WIFI管家</span
              >、<span class="b1">QQ同步助手</span>、<span class="b1"
                >阿里云OS</span
              >等多个应用商店的核心代理，并得到中国移动集团认可，推广其旗下咪咕系列APP
            </p>
          </div>
          <div class="line">
            <img
              src="../assets/yuanquan.svg"
              style="position: absolute; top: 951px; z-index: 3"
            />
            <h6 class="title16">2015</h6>
            <p class="intro12">
              公司成立，开启效果营销业务 ，为客户提供APP推广。服务于<span
                class="b1"
                >同程旅游</span
              >、<span class="b1">芒果TV</span>、<span class="b1">QQ阅读</span
              >、<span class="b1">手机百度</span
              >等知名产品，并带来数百万有效新增用户
            </p>
          </div>
        </div>
      </div>
      <div class="middle4">
        <h6 class="title1">PARTNERS</h6>
        <h6 class="title2">合作伙伴</h6>
        <div class="box">
          <img
            src="../assets/baidupp@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/QQllq@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/migu@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/youku@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/dcd@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/douyin@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/xiecheng@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/snyg@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/UC@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/mgTV@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/mryx@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/xgsp@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/huoshan@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/tcly@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
          <img
            src="../assets/kkmh@2X.png"
            style="width: 226px; height: 92px; margin-bottom: 30px"
          />
        </div>
      </div>
      <!-- 底部内容 -->
      <vbottom></vbottom>
    </div>
  </div>
</template>

<script>
import { banner } from "@/api/index";
import vbottom from "../components/bottom";
export default {
  data() {
    return {
      list: [
        {
          img: require("../assets/shjf.svg"),
          title: "珊瑚积分平台",
          descrite:
            "积分媒介，用户运营玩法带来自增长，利用社交流量池、新兴流量池，实现用户的全新增长方式",
          link: "/About#aaa",
        },
        {
          img: require("../assets/rhdsp.svg"),
          title: "TIANZ融合DSP平台",
          descrite:
            "TIANZ DSP平台依托核心智能推荐技术，" +
            "与国内大量" +
            "中小媒体" +
            "达成" +
            "战" +
            "略" +
            "合作，整合安卓端和IOS端优质流量资源",
          link: "/About#bbb",
        },
        {
          img: require("../assets/mtzyicon@2X.png"),
          title: "核心媒体资源",
          descrite:
            "1.腾讯安全工具APP" +
            "\u00A0\u2002\u2003\u2003" +
            "2.中国移动咪咕广告平台" +
            "\u00A0\u2002" +
            "3.掌上高铁\n4.今日头条广告平台",
          link: "/About#ccc",
        },
        {
          img: require("../assets/kjdsicon@2X.png"),
          title: "跨境电商",
          descrite:
            "我们是阿里SKA客户，营销资源充足，" +
            "经营范围广，业务辐射整个欧美地区，并且正在向其他地区持续扩展",
          link: "/About#ddd",
        },
      ],
      imageList: "",
    };
  },
  components: { vbottom },
  mounted() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      banner().then((data) => {
        this.imageList = JSON.parse(data.imageList)[0];
      });
    },
  },
};
</script>
<style scoped>

.beijingtupian {
  /* background: url("../assets/banner@2X.png"); */
  width: 100%;
  height: 500px;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  min-width: 1440px;
}
.waitao {
  position: relative;
  width: 1180px;
  top:-500px;
  left:295px;
  margin:auto
}
.middle {
  width: 1180px;
  height: 270px;
  margin: auto;
}
.title1 {
  width: 1180px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #868e94;
  line-height: 14px;
  margin-top: 80px;
  text-align: center;
}
.title2 {
  width: 1180px;
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  margin-top: 10px;
  text-align: center;
}
.intro1 {
  width: 1180px;
  height: 96px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
  margin: 40px auto;
}
.middle1 {
  width: 1180px;
  height: 726px;
  background: #f7faff;
  margin: auto;
}
.title3 {
  width: 1180px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #868e94;
  line-height: 14px;
  padding-top: 60px;
  text-align: center;
}
.sp {
  margin-top: 40px;
  width: 1180px;
  height: 458px;
  display: flex;
}
.sp1 {
  width: 236px;
  height: 419px;
  border-radius: 4px;
  float: left;
  margin: 0 auto;
  padding-top: 39px;
  position: relative;
}
.jiantou {
  background: url("../assets/jt-st.svg");
  width: 20px;
  height: 20px;
  background-size: cover;
  position: absolute;
  top: 396px;
  left: 109px;
}
.jiantou1 {
  background: url("../assets/jt-st.svg");
  width: 20px;
  height: 20px;
  background-size: cover;
  position: relative;
  top: 357px;
  left: 109px;
}
.jiantou2 {
  background: url("../assets/jt-st.svg");
  width: 20px;
  height: 20px;
  background-size: cover;
  position: relative;
  top: 28px;
  left: 109px;
}
.jiantou3 {
  background: url("../assets/jt-st.svg");
  width: 20px;
  height: 20px;
  background-size: cover;
  position: relative;
  top: 1px;
  left: 109px;
}
.sp1:hover {
  background: #ffffff;
  box-shadow: 0px 0px 60px 0px rgba(0, 95, 255, 0.08);
}
.sp1:hover .jiantou {
  background: url("../assets/jt-djt.svg");
}
.sp1:hover .jiantou1 {
  background: url("../assets/jt-djt.svg");
}
.sp1:hover .jiantou2 {
  background: url("../assets/jt-djt.svg");
}
.sp1:hover .jiantou3 {
  background: url("../assets/jt-djt.svg");
}
.title4 {
  width: 236px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  text-align: center;
  margin-top: 10px;
}
.intro2 {
  width: 186px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  margin: 30px auto;
  text-align: justify;
  word-break: break-all;
  white-space: pre-wrap;
}
.title5 {
  width: 116px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #005fff;
  line-height: 16px;
  position: relative;
  top: 40px;
  left: 532px;
}
.middle2 {
  width: 1180px;
  height: 654px;
  margin: auto;
}
.title6 {
  width: 553px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: -390px;
  left: 560px;
}
.dian {
  width: 10px;
  height: 10px;
  background: #005fff;
  border-radius: 5px;
  opacity: 0.19;
  position: absolute;
  top: -2px;
  left: -2px;
}
.dian1 {
  width: 6px;
  height: 6px;
  background: #005fff;
  border-radius: 3px;
  position: relative;
  top: -355px;
  left: 560px;
}
.dian2 {
  width: 6px;
  height: 6px;
  background: #005fff;
  border-radius: 3px;
  position: relative;
  top: -338px;
  left: 560px;
}
.dian3 {
  width: 6px;
  height: 6px;
  background: #005fff;
  border-radius: 3px;
  position: relative;
  top: -318px;
  left: 560px;
}
.title7 {
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: -367px;
  left: 577px;
}
.title8 {
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: -350px;
  left: 577px;
}
.title9 {
  width: 72px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: relative;
  top: -330px;
  left: 577px;
}
.intro3 {
  width: 600px;
  height: 52px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: relative;
  top: -360px;
  left: 577px;
}
.intro4 {
  width: 600px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: relative;
  top: -345px;
  left: 577px;
}
.intro5 {
  width: 600px;
  height: 52px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: relative;
  top: -320px;
  left: 577px;
}
.b1 {
  color: #005fff;
}
.middle3 {
  width: 1180px;
  height: 1090px;
  margin: 0 auto;
  background: #f7faff;
  position: relative;
}
.title10 {
  width: 72px;
  height: 32px;
  font-size: 32px;
  font-weight: 500;
  color: #005fff;
  line-height: 32px;
  position: absolute;
  top: 162px;
  left: 80px;
}
.title11 {
  width: 72px;
  height: 32px;
  font-size: 32px;
  font-weight: 500;
  color: #005fff;
  line-height: 32px;
  position: absolute;
  top: 280px;
  left: 80px;
}
.title12 {
  width: 72px;
  height: 32px;
  font-size: 32px;
  font-weight: 500;
  color: #005fff;
  line-height: 32px;
  position: absolute;
  top: 398px;
  left: 80px;
}
.title13 {
  width: 72px;
  height: 32px;
  font-size: 32px;
  font-weight: 500;
  color: #005fff;
  line-height: 32px;
  position: absolute;
  top: 540px;
  left: 80px;
}
.title14 {
  width: 72px;
  height: 32px;
  font-size: 32px;
  font-weight: 500;
  color: #005fff;
  line-height: 32px;
  position: absolute;
  top: 685px;
  left: 80px;
}
.title15 {
  width: 72px;
  height: 32px;
  font-size: 32px;
  font-weight: 500;
  color: #005fff;
  line-height: 32px;
  position: absolute;
  top: 830px;
  left: 80px;
}
.title16 {
  width: 72px;
  height: 32px;
  font-size: 32px;
  font-weight: 500;
  color: #005fff;
  line-height: 32px;
  position: absolute;
  top: 948px;
  left: 80px;
}
.intro6 {
  width: 657px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 214px;
  left: 80px;
}
.intro7 {
  width: 703px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 332px;
  left: 80px;
}
.intro8 {
  width: 1033px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 450px;
  left: 80px;
}
.intro9 {
  width: 1103px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 592px;
  left: 80px;
}
.intro10 {
  width: 1032px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 737px;
  left: 80px;
}
.intro11 {
  width: 1032px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 882px;
  left: 80px;
}
.intro12 {
  width: 1032px;
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 1000px;
  left: 80px;
}
.middle4 {
  width: 1180px;
  height: 510px;
  margin: 0 auto;
}
.box {
  margin-top: 40px;
  width: 1180px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
