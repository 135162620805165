import axios from 'axios'
let baseUrl = ''
if (process.env.NODE_ENV === "development") {
  baseUrl = "http://47.94.153.208:3000/api";
} else if (process.env.NODE_ENV === "production") {
  baseUrl = "http://47.94.153.208:3000/api";
} else if (process.env.NODE_ENV === "test") {
  baseUrl = "http://47.94.153.208:3000/api";
}
const service = axios.create({
  baseURL: baseUrl,
  timeout: 50000
})

//响应拦截器

service.interceptors.response.use((res) => {
  const { code, data, msg } = res.data;
  if (code === 200) {
      return data ;
  } else {
      return Promise.reject(msg)
  }
})

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
 export function post(url, params) {
  return new Promise((resolve, reject) => {
    service.post(url, params).then(res => { 
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export function get(url, params = '') {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}


