<template>
  <div id="app" v-title data-title="天卓科技">
    <div class = "top">
      <div id="xuanfu">
      <!-- 导航栏 -->
      <div class = "top1 clearfix">
        <div class = "logo"><img src ="./assets/companylogo.svg"></div>
        <div class="b" id="nav" >
            <router-link to="/Home"><span>关于我们</span><div class="linex"></div></router-link>       
            <router-link to="/About"><span>业务介绍</span><div class="linex"></div></router-link>
            <router-link to="/Culture"><span>企业文化</span><div class="linex"></div></router-link>
            <a href="http://bbs.tianzhuobj.com/index.html"><span>天卓课堂</span><div class="linex"></div></a>
            <router-link to="/Join"><span>加入我们</span><div class="linex"></div></router-link>
            <!-- <router-link to="/Media"><span>企业咨询(收费)</span><div class="linex"></div></router-link> -->
        </div>
      </div>
      <div  class ="dianhua">
        <div class="button2"  id="b2"></div> 
          <div class="dianhua4" id="d3">
              <img src ="./assets/qipao@2x.png" style="position:relative;width:167px;height:70px;top:-16px;left:-13.5px">
              <p class="dianhua5">010-63720140</p>
          </div>
      </div>
      <div class="anniu" id="backtop" v-show="totop">
        <img src ="./assets/zhidingicon.svg" @click="backtop"
            class="backtop">
      </div>
    </div>
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
       totop: false
    }
  },
  components: {},
  mounted() {
    console.log();
        window.addEventListener("scroll", this.scrolltotop);
  },
   destroyed() {
        window.removeEventListener("scroll", this.scrolltotop);
    },

  methods:{
      scrolltotop() {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop > 400) {
                this.totop = true;
            } else {
                this.totop = false;
            }
        }, backtop() {
            let back = setInterval(() => {
                if (
                    document.body.scrollTop ||
                    document.documentElement.scrollTop
                ) {
                    document.body.scrollTop -= 100;
                    document.documentElement.scrollTop -= 100;
                } else {
                    clearInterval(back);
                }
            });
        }
  }
}
</script> 
<style>
*{
    margin:0; padding:0;
    
    }
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, button, textarea, p, blockquote, th, td { margin: 0; padding: 0; }
fieldset, img { border: 0; }
ul, ol, li { list-style: none;  }
body,td,th,title {font-family: Helvetica Neue,Helvetica,Tahoma,Arial,PingFang SC,Hiragino Sans GB,Heiti SC,Microsoft YaHei,SimHei,WenQuanYi Micro Hei,sans-serif !important;}
/* remember to define focus styles! */
:focus { outline: 0; }
address, caption, cite, code, dfn, em, strong, th, var, optgroup { font-style: normal; font-weight: normal; }
h1, h2, h3, h4, h5, h6 { font-size: 100%; font-weight: normal; }
abbr, acronym { border: 0; font-variant: normal; }
input, button, textarea, select, optgroup, option { font-family:Helvetica Neue,Helvetica,Tahoma,Arial,PingFang SC,Hiragino Sans GB,Heiti SC,Microsoft YaHei,SimHei,WenQuanYi Micro Hei,sans-serif !important; font-size: inherit; font-style: inherit; font-weight: inherit; background-color: #fff; border: none;}
code, kbd, samp, tt { font-size: 100%; }
a{text-decoration:none;}
a:hover{
    text-decoration: none;
}
/*@purpose To enable resizing for IE */
/*@branch For IE6-Win, IE7-Win */
input, button, textarea{ *font-size: 100%;}
ol, ul { list-style: none; }

/* tables still need 'cellspacing="0"' in the markup */
table,tr,td { border-collapse: collapse; border-spacing: 0; margin:0;padding:0;}
caption, th { text-align: left; }
sup, sub { font-size: 100%; vertical-align: baseline; }

/* remember to highlight anchors and inserts somehow! */
blockquote, q { quotes: none; }
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

/**Common Css**/
.fr { float: right; *display: inline;}
.fl { float: left; *display: inline;}
/*文本超出显示省略号*/
.ehn, .otw, .tow { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
/*清除浮动*/
.clearfix { clear: both; *zoom:1;}
.clearfix:after { display: block; clear: both; content: "\0020"; visibility: hidden; height: 0; }
.top{
  width: 100%;
  height:64px;
}
.top1{
  margin:0 auto ;
  overflow: hidden;
  width:1180px;
}
div#xuanfu{
  display: block;
  z-index: 1000;
  height: 64px;
  width: 100%;
  top: 0;
  background: #fff;
  position: fixed;
  box-shadow: 0px 1px 2px 0px rgb(47 56 111 / 8%);
}
.logo{
  margin:16px 1px 16px 0;
  width: 126px;
  height: 32px;
  float: left;
}
.b{
  display: block;
  float: right;
  height: 48px;
  text-align: center;
}
#nav a{
  color: #333333;
  font-weight: 400;
  float: left;
  width: 124px;
  margin: 20px 0 0 0;
  line-height: 22px;
}
.router-link-active
  div
   {
   background: #005FFF;
  }
.router-link-active
  span
  {
   color: #005FFF
  }
#nav  a:hover ,.router-link-exact-active{
  color: #005FFF;
}
.dianhua{
  height:42px;
  width: 42px;
  position: fixed;
  top: 600px;
  right: 30px;
  z-index: 999;
}
.anniu{
  height:42px;
  width: 42px;
  position: fixed;
  top: 662px;
  right: 30px;
  z-index: 999;
  box-shadow: 0px 0px 16px 0px rgba(0, 95, 255,0.1);
  border-radius: 21px;
  border: 1px solid rgba(0, 95, 255, 0.05);
}
.button2{
  width: 42px;
  height: 42px;
  z-index: 999;
  background: url('./assets/dianhuaicon.svg');
  background-size:cover;
  box-shadow: 0px 0px 16px 0px rgba(0, 95, 255,0.1);
  border-radius: 21px;
}
.dianhua4{
  margin: 40px 0 0 0;
  width: 200px;
  height: 70px;
  z-index: 999;
  float: left;
  position: absolute;
  top:-37px;
  left:-149px;
  display:none;
}
.dianhua5{
  width: 105px;
  height: 16px;
  z-index: 999;
  font-size: 16px;
  color: #005FFF;
  line-height: 16px;
  position: absolute;
  left:15px;
  top:12.5px;
}
.dianhua:hover .dianhua4{
  display:block;
 }
.linex{
  width: 64px;
  height: 4px;
  background: #FFFFFF;
  border-radius: 2px;
  position: relative;
  top:18px;
  left:29px
}
.scrollTop{
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
.backTop {
  position: fixed;
  bottom: 50px;
  z-index: 100;
  right: 0;
  background: black;
}

</style>
