<template>
  <!-- 底部内容 -->
    <div>
      <div style="width:100%;background: #1C2333;">
        <div class="bottom">
        <h6 class="title17">关于我们</h6>
        <h6 class="title18">业务介绍</h6>
        <h6 class="title19">企业文化</h6>
        <h6 class="title20">加入我们</h6>
        <h6 class="title21">联系我们</h6>
        <h6 class="title22">关注我们</h6>
        <p class="intro13">公司介绍</p>
        <p class="intro14">主营业务</p>
        <p class="intro15">我们的优势</p>
        <p class="intro16">发展历程</p>
        <p class="intro17">合作伙伴</p>
        <p class="intro18">珊瑚积分平台</p>
        <p class="intro19">融合DSP平台</p>
        <p class="intro20">核心媒体资源</p>
        <p class="intro21">跨境电商</p>
        <p class="intro22">精选成功案例</p>
        <p class="intro23">企业文化</p>
        <p class="intro24">经营理念</p>
        <p class="intro25">公司活动</p>
        <p class="intro26">办公地点</p>
        <p class="intro27">招聘详情</p>
        <p class="intro28">咨询电话</p>
        <div class="line1"></div>
     <!--    <div v-for="(item,index) in  list" :key="index">
          <p class="intro29">{{item.tel}}</p>
          <p class="intro30">{{item.business}}</p>
          <div class="line2"></div>
          <p class="intro31">{{item.businessemail}}</p>
          <p class="intro32">{{item.operate}}</p>
          <div class="line3"></div>
          <p class="intro33">{{item.operateemail}}</p>
          <img :src ="item.img1" style="width:120px;height:120px;position:absolute;top:106px;left:900px;">
          <img :src ="item.img2" style="width:120px;height:120px;position:absolute;top:106px;left:1060px;"> </div>-->
        <p class="intro29" v-html="phone">{{phone}}</p>
        <p class="intro30" v-html="businessName">{{businessName}}</p>
        <div class="line2"></div>
        <p class="intro31" v-html="businessAddress">{{businessAddress}}</p>
        <p class="intro32" v-html="operateName">{{operateName}}</p>
        <div class="line3"></div>
        <p class="intro33" v-html="operateAddress">{{operateAddress}}</p>
        <img :src ="followImgOne" style="width:120px;height:120px;position:absolute;top:106px;left:900px;">
        <img :src ="followImgTwo" style="width:120px;height:120px;position:absolute;top:106px;left:1060px;">
        <img src ="../assets/fxicon.svg" style="position:absolute;top:282px;left:902px;">
        <div class="line4"></div>
        <img src ="../assets/wbicon.svg" style="position:absolute;top:282px;left:937px;">
        <img src ="../assets/wxicon.svg" style="position:absolute;top:282px;left:964px;">  
        <p class="intro34">广告合作</p>
        <p class="intro35">媒体合作</p>
        </div>
      </div>
      <div style="width:100%;background: #111828;">
        <div class ="foot">
          <a target="_blank" href="https://beian.miit.gov.cn/"><span class="intro36">备案号: 京ICP备15016065号-1</span></a>
        </div>
      </div>
    </div>  
</template>
<script>
import {information} from "@/api/index";
export default {
  name:'vbottom',
  data() {
    return {
      phone:"",
      businessName:"",
      businessAddress:"",
      operateName:"",
      operateAddress:"",
      followImgOne:"",
      followImgTwo:""
    }
},
  mounted() {
    this.getInformation()
  },
   methods:{
     getInformation() {
     information().then((data) => {
     this.phone=data.phone;
     this.businessName=data.businessName;
     this.businessAddress=data.businessAddress;
     this.operateName=data.operateName;
     this.operateAddress=data.operateAddress;
     this.followImgOne=data.followImgOne;
     this.followImgTwo=data.followImgTwo;
      });
     }
   }
}
</script>
<style>
.bottom{
  width:1180px;
  height: 325px;
  background: #1C2333;
  margin:0 auto;
  position: relative;
}
.title17{
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #CCCCCC;
  line-height: 16px;
  position:absolute;
  top:60px;
}
.title18{
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #CCCCCC;
  line-height: 16px;
  position:absolute;
  top:60px;
  left:160px;
}
.title19{
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #CCCCCC;
  line-height: 16px;
  position:absolute;
  top:60px;
  left:320px;
}
.title20{
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #CCCCCC;
  line-height: 16px;
  position:absolute;
  top:60px;
  left:480px;
}
.title21{
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #CCCCCC;
  line-height: 16px;
  position:absolute;
  top:60px;
  left:640px;
}
.title22{
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #CCCCCC;
  line-height: 16px;
  position:absolute;
  top:60px;
  left:900px;
}
.intro13{
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:106px;
}
.intro14{
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:140px;  
}
.intro15{
  width: 70px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:174px;  
}
.intro16{
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:208px;  
}
.intro17{
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:242px;  
}
.intro18{
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:106px;  
  left: 160px;
}
.intro19{
  width: 85px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:140px;  
  left: 160px;
}
.intro20{
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:174px;  
  left: 160px;
}
.intro21{
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:208px;  
  left: 160px;
}
.intro22{
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:242px;  
  left: 160px;
}
.intro23{
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:106px;  
  left:320px;
}
.intro24{
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:140px;  
  left:320px;
}
.intro25{
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:174px;  
  left:320px;
}
.intro26{
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:106px;  
  left:480px;
}
.intro27{
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:140px;  
  left:480px;
}
.intro28{
  width: 84px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:108px;  
  left:640px;
}
.line1{
  width: 56px;
  height: 1px;
  background: #868E94;
  border-radius: 1px;
  position:absolute;
  top:124px;  
  left:640px;
}
.line2{
  width: 56px;
  height: 1px;
  background: #868E94;
  border-radius: 1px;
  position:absolute;
  top:185px;  
  left:640px;
}
.line3{
  width: 56px;
  height: 1px;
  background: #868E94;
  border-radius: 1px;
  position:absolute;
  top:244px;  
  left:640px;
}
.intro29{
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 14px;
  position:absolute;
  top:133px;  
  left:640px;
}
.intro30{
  width: 118px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:167px;  
  left:640px;
}
.intro31{
  height: 16px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 14px;
  position:absolute;
  top:194px;  
  left:640px;
}
.intro32{
  width: 118px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:226px;  
  left:640px;
}
.intro33{
  height: 16px;
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 14px;
  position:absolute;
  top:253px;  
  left:640px;
}
.intro34{
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;;
  line-height: 14px;
  position:absolute;
  top:236px;  
  left:900px;
}
.intro35{
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #868E94;
  line-height: 14px;
  position:absolute;
  top:236px;  
  left:1060px;
}
.line4{
  width: 1px;
  height: 16px;
  background: #868E94;
  border-radius: 1px;
  position:absolute;
  top:280px;  
  left:926px;
}
.foot{
  width:227px;
  margin: 0 auto;
  background: #111828;
  height:50px;
}
.intro36{
  display: block;
  font-size: 16px;
  color: #868E94;
  letter-spacing: 0;
  text-align: center;
  padding-top: 14px;
  margin: 0  auto;
}
</style>