import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/Home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/culture',
    name: 'Culture',
    component: () => import(/* webpackChunkName: "culture" */ '../views/Culture.vue'),
      } ,
      {
        path: '/activities',//以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: 'Activities',
        component: () => import(/* webpackChunkName: "activities" */ '../components/Activities.vue'),
      } ,   
      {
        path: '/moreActs',//以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: 'moreActs',
        component: () => import(/* webpackChunkName: "moreActs" */ '../components/moreActs.vue'),
      }  , 
  {
    path: '/join',
    name: 'Join',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "culture" */ '../views/Join.vue')
  } ,
  {
    path: '/media',
    name: 'Media',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "culture" */ '../views/Media.vue')
  }
]

const router = new VueRouter({
  scrollBehavior(to,from,saveTop){

    if(saveTop){
    
    return saveTop;
    
    }else{
    
    return {x:0,y:0}
    
    } },
  routes
})

export default router
