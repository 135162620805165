import {
  post
  
} from './http'

//关于我们
export const banner = data=> post('/about/banner',data) //获取banner

//业务介绍
export const businessBanner = data=> post('/business/info',data) //获取数据

//企业文化
export const enterpriseBanner = data=> post('/enterprise/banner',data) //获取banner
export const activeList = data=> post('/enterpriseActivity/activeList',data) //获取活动内容
export const activeById = data=> post('/enterpriseActivity/activeById',data) //获取活动详情

//信息管理
export const information = data=> post('/information/getInformation',data) //获取信息